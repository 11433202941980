.main-System{
  /* display: grid; */
}

.mini-System{
  display:flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  height:100px;
  min-width: 2137px;
}

.mini-System2{
  display:flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  height:200px;
  min-width: 2137px;
}

.box1-System{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  width: 400px;
  min-height: 180px;
  flex: auto;
}

.fileprev-heading {
  margin-top: 130px;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-content: center;
  font-size: 30px;
  color: white;
  border-radius: 50%;
  border: solid 2px  #0f2039e1;
  text-align: center;
  background-color:  #0f2039e1;
}

.box1-System-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: auto;
}

.box1-System-row > * {
  margin-right: 20px;
}

.box1-System-filter{
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold; 
  color:white;
  margin-bottom: 20px;
}

.box1-System-preview{
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  color:white;
  margin-bottom: 20px;
  float: right;
}

.box2-System{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  width:50%;
  height: 950px;
  flex: auto;
}