.main-dns{
  display: grid;
}

.mini-dns{
  display:flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 2137px;
}

.box1-dns{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  width: 400px;
  min-height: 140px;
  /* height:100px; */
  flex: auto;
}

.box1-dns-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: auto;
}

.box1-dns-row > * {
  margin-right: 20px;
}

.box1-dns-filter{
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  color:white;
  margin-bottom: 30px;
}

.box1-dns-date{
  font-size: 16px;
  font-family: sans-serif;
  font-weight:200;
  color:white;
  margin-bottom: 10px;
}

.test{
  color:white;
}

.box2-dns{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  width:50%;
  min-height: 550px;
  flex: auto;
}



.custom-datetime-picker label {
  color: rgba(75,192,192,0.9);
  font-size: 14px;
}

.simplebar-content {
  min-width: 400px;
  max-height: 20rem;
}

.simplebar-track.simplebar-vertical {
  background-color: #0f2039e1;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: rgba(75,192,192,0.8)
}

.filter-elements-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 5px; /* Add padding to ensure space between elements and scrollbar */
  padding-top: 10px;
}

.filter-element {
  white-space: nowrap;
  color: white;
  margin-right: 15px;
  margin-bottom: 6px;
  padding: 3px;
  border: 1px solid rgba(75,192,192,1)
}



/* SelectCustomStyles.css */
.custom-select-control {
  background-color: transparent;
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 20px;
  max-width: 120px;
}

.custom-select-menu {
  max-height: 200px;
  overflow: hidden;
  max-width: 120px;
}

.custom-select-menuList {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.custom-select-option {
  color: red !important; /* Adding !important to override any potential conflicts */
}

.custom-select-singleValue {
  color: black !important; /* Adding !important to override any potential conflicts */
}

.custom-select-placeholder {
  color: black !important; /* Adding !important to override any potential conflicts */
}





/* .box3-dns{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  min-height: 400px;
  flex: auto;
} */








/* Box3-dns styles */
.box3-dns {
  background-color: #0f2039e1;
  padding: 1rem;
  margin: 0.2rem;
  min-width: 400px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 450px
}

.con-container {
  display: grid;

  /* justify-content: space-between;  */
  width: 100%; 
  height: 100%;
}

/* Container for circles and arrow */
.connection-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute the children elements to space them evenly */
  width: 100%; /* Take full width of the container */
}

.circle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto; /* Allow the circle to expand according to its content */
  height: 50px; /* Set the desired height */
  border-radius: 50%; /* Make it a circle */
  background-color: #e0e0e0; /* Light gray color for circles */
  padding: 0 15px; /* Optional: Add some horizontal padding for spacing */
  text-align: center; /* Center the text */
  margin: 0; /* Remove margin to fit closely */
}

/* Arrow styles */
.arrow {
  flex-grow: 1;
}

.text-rect-right{
  /* border: #ccc 2px solid; */
  margin-left: 60%;
  font-size: 20px;
  color: white;
  width: 550px;
  height: 300px;
  display: inline-block; /* Change display to inline-block */
  padding: 10px; /* Add padding for spacing */
  word-wrap: break-word; /* Allows words to wrap */
  overflow: hidden; /* Hides overflowed content */
}

.text-rect-left{
  /* border: #ccc 2px solid; */
  margin-top: 25px;
  margin-left: 20%;
  font-size: 20px;
  color: white;
  width: 400px;
  height: 60px;
  display: inline-block; /* Change display to inline-block */
  padding: 10px; /* Add padding for spacing */
  word-wrap: break-word; /* Allows words to wrap */
  overflow: hidden; /* Hides overflowed content */
}