.auth-form {
    width: 25rem;
    max-width: 80%;
    margin: 10rem auto;
    /* border: 1px red solid; */
    height: 300px;
    background: #0f20398a;
    padding: 1rem 1rem;
  }

.auth-form h1{
  /* color: rgba(255, 255, 255, 0.4); */
  color:rgba(75, 192, 192, 1);
  text-align: center;
}

.form-control label{
  width: 98%;
  display: block;
  /* color: white; */
  color: rgba(255, 255, 255, 0.751);
  margin-bottom: 0.5rem;
}
.form-control input {
  width: 98%;
  display: block;
}

.form-control {
  margin-bottom: 1rem;
}
.form-actions{
  display: flex; 
  justify-content: flex-end
}
.form-actions button {
  background: transparent;
  font: inherit;
  border: 1px rgba(255, 255, 255, 0.4) solid ;
  border-radius: 3px;
  padding: 0.25rem 1rem;
  margin-left: auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
  color: white;
  cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active {
    /* background: #0e4040;
    border-color: #0e4040; */
}

.error-message{
  font-size: 16px;
  color:rgb(158, 171, 233)
}