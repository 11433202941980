/* html{
  font-family: sans-serif;
  width:100%;
}
body {
  margin: 0;
  padding:0;
  background-color: #0f2039e1;
  display: flex;
  justify-content: center;
  width:100%;
  min-width: 2138px;
  height:1287px;
} */

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; /* Set height to 100% */
  font-family: sans-serif;
}

body {
  background-color: #0f2039e1;
  display: flex;
  justify-content: center;
}