.main-beac{
  display: grid;
}

.mini-beac{
  display:flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 2137px;


}

.box-beac{
    background-color: #0f2039e1;
    padding: 60px 5px;
    margin: .2rem .2rem;
    min-width: 500px;
    min-height: 450px;
    flex: auto;
}

.box1-beac{
  background-color: #0f2039e1;
  padding: 100px 30px;
  margin: .2rem .2rem;
  min-width: 500px;
  min-height: 300px;
  flex: auto;
}
.box1-beac-main{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  width:1000px;
  height: 300px;
  flex: auto;
  /* overflow-y: auto; 
  border: 1px solid #ccc;  */
}

.box1-beac-list-filter{
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  color:white;

  /* padding: 1rem 1rem; */
  margin: .2rem .2rem;
  margin-bottom: 20px;
}
.box1-beac-list-date{
  font-size: 16px;
  font-family: sans-serif;
  font-weight:200;
  color:white;

  /* padding: 1rem 1rem; */
  margin: .2rem .2rem;
  margin-bottom: 10px;
}
.box1-beac-list{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  width: 400px;
  /* max-width:200px; */
  min-height: 400px;
  flex: auto;
}
.custom-datetime-picker label {
  color: rgba(75,192,192,0.9); 
  font-size: 14px;
}

.box1-beac-list ul{
  padding: 0;
  margin:0;
}

.box1-beac-list li{
  padding: .2rem;
  cursor: pointer;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: bold;
}

.box1-beac-list li:hover {
  background-color:#0f2039e1;
  border:solid 1px rgba(255,255,255,1);
  width: 380px;
  color:rgba(75,192,192,1);
}

.box1-beac-list li.selected {
  background-color:#0f203900;
  border:solid 1px rgba(255,255,255,1);
  width: 380px;
  color:rgba(75,192,192,1);
}

.simplebar-content {
  min-width: 400px;
  max-height: 20rem;
}

.simplebar-track.simplebar-vertical {
  background-color: #0f2039e1;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: rgba(75,192,192,0.8)
} 


.details{
  padding: .2rem;
  cursor: pointer;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: bold;
}

.search-container {
  position: relative;
  display: inline-flex;
    align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;


}

.search-container [type="text"] {
  padding: 8px;
  border: none;
  margin-right: 10px;
  background-color: transparent;
  outline: none;
  color: white;
  font-weight: bold;
  font-size: 16px;

}

.search-container [type="text"]::placeholder {
  color: #ccc; 
  font-style: italic; 
  font-weight: bold;
  font-size: 16px;
}

.search-icon,
.reset-icon {
  cursor: pointer;
  margin-left: 5px;
}

.dialog-customB {
  background-color: #0f2039e1;
}
.dialog-custom-titleB{
  background-color: #0f2039e1;
  color:white
}