.collapsible-content {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .collapsible-content.expanded {
    white-space: normal;
  }
  .toggle-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left:0;
  }
  
  .arrow-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px; 
    height: 20px; 
    border-radius: 50%; 
    /* background-color: rgba(75,192,192,1); */
  }
  
  .arrow-circle svg {
    /* color: #0f2039e1; */
    color: white;
  }
  