/* .main-content{
    margin-top: 8rem;
    margin-right:0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex: auto;
} */

.main-content {
    margin-top: 4rem;
    margin-right: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex: auto;
    height: 100%;
  }

/* .main-content {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    flex: auto;
  } */

  