.clickable-item {
    cursor: pointer;
    margin-right: 5px;
    color: 'green';
    text-decoration: 'none';
  }
  
  .clickable-item:hover {
    text-decoration: underline;
    color: 'green';
  }
  
  .green-text {
    color: rgb(74, 221, 74);
  }
  
  .blue-text {
    color: rgb(79, 199, 235);
  }
  
  .field-title{
    color: white;
    font-size: 20px;
    font-weight: 300;
  }