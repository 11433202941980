.main-stream{
  display: grid;
}

.mini-stream{
  display:flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 2137px;
}

.box1-stream{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  width: 400px;
  min-height: 140px;
  height:100px;
  flex: auto;
}

.box1-stream-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: auto;
}

.box1-stream-row > * {
  margin-right: 20px;
}

.box1-stream-filter{
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  color:white;
  margin-bottom: 30px;
}

.box1-stream-date{
  font-size: 16px;
  font-family: sans-serif;
  font-weight:200;
  color:white;
  margin-bottom: 10px;
}

.test{
  color:white;
}

.box2-stream{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  height: 1200px;
  flex: auto;
}

.box3-stream{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  min-height: 400px;
  flex: auto;
}

.custom-datetime-picker label {
  color: rgba(75,192,192,0.9);
  font-size: 14px;
}

.simplebar-content {
  min-width: 400px;
  max-height: 1000px;
}

.simplebar-track.simplebar-vertical {
  background-color: #0f2039e1;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: rgba(75,192,192,0.8)
}




.custom-table-container {
  max-height: 1100px;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th{
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 18px;
}
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color: #333;
  font-size: 16px;
}

.custom-table thead {
  background-color: #343a40;
  color: #fff;
  position: sticky;
  top: 0;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.custom-table tbody tr:nth-child(odd) {
  background-color: #ccc;
}
