.main-navigation {
    position:fixed;
    left:0;
    top:0;
    width: 100%;
    height: 3.5rem;
    background: #02102B;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    z-index: 1;
    margin:0;
}

.main-navigation__logo h1{
    margin:0;
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.75);
}

.main-navigation__items {
margin-left: 1.5rem;
}

.main-navigation__items ul{
    display: flex;
    list-style: none;
    padding: 0;
    margin:0;
    align-items: center;
}

.main-navigation__items li{
    margin:0 1rem;
}

.main-navigation__items a,
.main-navigation__items button{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.751);
    padding: 0.25rem 0.5rem;
    border: none;
    /* font: inherit; */
    font-size: .8rem;
    background: transparent;
    cursor: pointer;
    margin: 0;
}

.main-navigation__items a:hover,
.main-navigation__items a:active,
.main-navigation__items a.active,
.main-navigation__items button:hover,
.main-navigation__items button:active{
    /* color: black;   */
    font-size: 1.2rem;

}

.main-navigation__items-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 94%;
}

.username {
    margin-left: auto; /* Move the username to the right */
    padding: 0.25rem 0.5rem;
    margin-right: 20px; /* recently added for modif logo */
}