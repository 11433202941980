.main-session{
  display: grid;
}
.dialog-custom {
  min-width: 900px; /* Set the minimum width */
  min-height: 400px; /* Set the minimum height */
  background-color: #0f2039e1;
}
.dialog-custom-title{
  background-color: #0f2039e1;
  min-width: 900px;
  color:white;
  text-align: center;
}

.dialog-query {
  min-width: 300px; /* Set the minimum width */
  min-height: 80px; /* Set the minimum height */
  width: 300px; /* Set the minimum width */
  height: 80px; /* Set the minimum height */
  background-color: #0f2039e1;
}
.dialog-query-title{
  background-color: #0f2039e1;
  color:white
}

.custom-text-field {
  width: 400px;
  background-color: transparent; 
}
.custom-text-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when not focused */
}

.custom-text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when hovered */
}

.custom-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when focused */
}
.custom-text-field .MuiInputBase-input {
  color: white; /* Change to your desired text color */
}

/* Change placeholder color of TextField */
.custom-text-field .MuiInputBase-input::placeholder {
  color: white; /* Change to your desired placeholder color */
}



.query-name-field {
  width: 280px;
  background-color: transparent; 
}
.query-name-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when not focused */
}

.query-name-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when hovered */
}

.query-name-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white; /* Border color when focused */
}
.query-name-field .MuiInputBase-input {
  color: white; /* Change to your desired text color */
}

/* Change placeholder color of TextField */
.query-name-field .MuiInputBase-input::placeholder {
  color: white; /* Change to your desired placeholder color */
}

.custom-checkbox:checked {
  accent-color: rgba(75,192,192,0.9);  /* Change the color here */
}
.custom-checkbox{
  width:17px;
  height:17px;
  cursor:pointer;
}

.mini-session{
  display:flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 2137px;
}

.box1-session{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  width: 400px;
  min-height: 140px;
  height:100px;
  flex: auto;
}

.box1-session-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: auto;
}

.box1-session-row > * {
  margin-right: 20px;
}

.box1-session-filter{
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  color:white;
  margin-bottom: 1px;
}

.box1-session-date{
  font-size: 16px;
  font-family: sans-serif;
  font-weight:200;
  color:white;
  margin-bottom: 10px;
}

.test{
  color:white;
}

.box2-session{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  min-width: 400px;
  height: 520px;
  width: 400px;
  flex: auto;
}

.box2-session-chart-header {
  min-width: 400px;
  /* height: 60px; */
  background-color: #0f2039e1;
  border: 2px solid #3c5477e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
}

.box2-session-date-left,
.box2-session-date-right {
  color: white;
  font-size: 20px; /* Adjust font size as needed */
  display: flex;
  align-items: center;
}

.box2-session-date-box {
  border: 1px solid #3c5477e1;
  padding: 1rem 1rem;
  /* margin-right: 10px; */
}

.box3-session{
  background-color: #0f2039e1;
  padding: 1rem 1rem;
  margin: .2rem .2rem;
  width: 400px;
  min-width: 400px;
  min-height: 400px;
  flex: auto;
}

.custom-datetime-picker label {
  color: rgba(75,192,192,0.9);
  font-size: 14px;
}
.custom-datetime-picker .MuiSvgIcon-root {
  color: rgba(75,192,192,0.9); /* Change the color to red */
}
.simplebar-content {
  min-width: 400px;
  max-height: 20rem;
}

.simplebar-track.simplebar-vertical {
  background-color: #0f2039e1;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: rgba(75,192,192,0.8)
}

.filter-elements-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 5px; /* Add padding to ensure space between elements and scrollbar */
  padding-top: 10px;
}

.filter-element {
  white-space: nowrap;
  color: white;
  margin-right: 15px;
  margin-bottom: 6px;
  padding: 3px;
  border: 1px solid rgba(75,192,192,1)
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px; /* Set your preferred width */
  max-width: 80%;
  padding: 20px;
  background-color: white !important;
  outline: none;
}